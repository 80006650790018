import { type Validator } from "@saas/shared/utils/^type/validator/index";

export type CashierDraftFormValues = {
  name: string;
  notes: string;
};

export const LOCALSTORAGE_DRAFT_ORDERS_KEY = "draft-orders-key";

interface CashierDraftValidation
  extends Omit<Validator<CashierDraftFormValues>, "name"> {
  name: (value: string, prevData: Record<string, boolean>) => string | boolean;
}
export const cashierDraftValidation: CashierDraftValidation = {
  name: (value, existingData) => {
    const isExist = existingData[value.toLowerCase()];

    if (isExist) return "Nama sudah terpakai, harap masukkan nama lain.";

    return true;
  },
};

export default cashierDraftValidation;
